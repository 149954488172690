import parsePhoneNumberFromString, { AsYouType, getCountries, getCountryCallingCode, getExampleNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import HeadlessSelectObject from './headless/selectObject.headless';

const getCountryCode = (code) => {
  const asYouType = new AsYouType();
  asYouType.input(code);
  const countryCode = asYouType.getCountry();
  return countryCode;
};

// A basic function to generate a pattern based on country code
const generatePhonePattern = (countryCode) => {
  try {
    const exampleNumber = getExampleNumber(countryCode);
    if (exampleNumber) {
      const nationalNumber = exampleNumber.nationalNumber;
      return `\\d{${nationalNumber.length}}`;
    }
  } catch (error) {
    console.error(`Pattern generation failed for ${countryCode}: `, error);
  }
  // Default pattern for numeric input
  return "\\d+";
};

const PhoneNumberInput = ({ phone_number: { code, number }, onPhoneNumberChange }) => {
  const [countryCode, setCountryCode] = useState('SA');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    if (code && countryCode !== getCountryCode(code)) {
      setCountryCode(getCountryCode(code))
    }
    if (number !== phoneNumber) {
      setPhoneNumber(number)
    }
  }, [code, number])


  const countryOptions = getCountries().map((countryCode) => ({
    value: countryCode,
    name: `+${getCountryCallingCode(countryCode)} - ${countryCode}`,
  }));

  const handleCountryChange = (value) => {
    const newCode = `+${getCountryCallingCode(value)}`;
    setCountryCode(value);
    setPhoneNumber('');
    onPhoneNumberChange({
      code: newCode,
      number: '',
    });
    setIsValid(undefined)
  };

  const handleNumberChange = (rawPhoneNumber) => {
    if (isNaN(rawPhoneNumber)) return;
    const parsedNumber = parsePhoneNumberFromString(rawPhoneNumber, countryCode);
    setPhoneNumber(rawPhoneNumber);
    onPhoneNumberChange({
      code: `+${getCountryCallingCode(countryCode)}`,
      number: rawPhoneNumber,
    });
    setIsValid(parsedNumber?.isValid())

  };


  return (
    <div className="flex w-full justify-center items-center gap-1">
      <div className='flex w-32'>
        <HeadlessSelectObject
          title="Select Country"
          options={countryOptions}
          selected={countryCode}
          onSelect={handleCountryChange}
        />
      </div>
      <div className='flex flex-auto'>
        <input
          disabled={!countryCode}
          type="text"
          name="number"
          value={phoneNumber}
          placeholder='phone number'
          pattern={generatePhonePattern(countryCode)}
          onChange={(e) => handleNumberChange(e.target.value)}
          className={`block w-full rounded-md border border-gray-300 px-3 py-2 text-sm shadow-sm ${(phoneNumber && isValid === false) ? 'border-red-300 focus:border-red-700 focus:ring-red-700' : (phoneNumber && isValid === true) ? 'focus:border-green-700 focus:ring-green-700' : ''} focus:outline-none`}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInput;

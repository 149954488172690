import { parsePhoneNumberFromString } from "libphonenumber-js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";

export const submitFormHandler = (formRef) => {
  if (formRef) {
    if (typeof formRef.requestSubmit === "function") {
      formRef.requestSubmit();
    } else {
      // Polyfill for requestSubmit
      const event = new Event("submit", {
        bubbles: true,
        cancelable: true,
      });
      formRef.dispatchEvent(event);
    }
  }
};

export const formatNumber = (value, decimals = 1) => {
  //return "$"+value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  if (value > 999 && value < 1000000) {
    return (value / 1000).toFixed(decimals) + " K"; // convert to K for valueber from > 1000 < 1 million
  } else if (value >= 1000000 && value < 1000000000) {
    return (value / 1000000).toFixed(decimals) + " M"; // convert to M for valueber from > 1 million
  } else if (value >= 1000000000 && value < 1000000000000) {
    return (value / 1000000000).toFixed(decimals) + " B"; // convert to M for valueber from > 1 million
  } else if (value >= 1000000000000) {
    return (value / 1000000000000).toFixed(decimals) + " T"; // convert to M for valueber from > 1 million
  } else if (value < 1000) {
    return value.toFixed(decimals); // if value < 1000, nothing to do
  }
};

export const isValidCode = (code) => {
  return code === "" || /^\+\d{0,4}$/.test(code);
};

export const isValidNumber = (number) => {
  return /^\d*$/.test(number);
};

export const validatePhoneNumber = (number) => {
  const phoneNumber = parsePhoneNumberFromString(number);
  console.log({ phoneNumberProerties: phoneNumber });
  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.isValid();
  } else {
    return false;
  }
};
export const validateCodePhoneNumber = (code, number) => {
  const fullNumber = `${code}${number}`;
  const phoneNumber = parsePhoneNumberFromString(fullNumber);
  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.isValid();
  } else {
    return false;
  }
};

export const roundToTwoDecimals = (value) => {
  const number = parseFloat(value);
  return isNaN(number)
    ? value
    : number % 1 !== 0
    ? number.toFixed(2)
    : value.toString();
};

export const formatNumberWithCommas = (amount) =>
  typeof amount === "number"
    ? amount.toLocaleString()
    : typeof amount === "string"
    ? parseFloat(amount).toLocaleString()
    : amount;

export const formatLocalTimeDate = (
  date,
  format = "MMM DD, YYYY hh:mm:ss A"
) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(relativeTime);
  const user_timeZone = dayjs.tz.guess();

  return dayjs(date).tz(user_timeZone).format(format);
};

export const convertDateTimeToUTC = (localDateTime) => {
  dayjs.extend(utc);
  return dayjs(localDateTime).utc().format();
};

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export const downloadFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

import Card from "components/card";
import PieChart from "components/charts/PieChart";
import { useState } from "react";
import { pieChartData, pieChartOptions } from "variables/charts";

import { useEffect } from "react";

import { setAlert } from "actions/alert/alert.actions";
import { useDispatch } from "react-redux";
import { AlertTypes } from "reducers/alert/alert.reducer";
import DashboardService from "services/dashboard/DashboardService";
import { formatNumber } from "utils/App.utils";
import { FaRegCalendarAlt } from "react-icons/fa";

const EarningsRedemptionsPieChart = () => {
  const dispatch = useDispatch();
  const colors = { earnings: "#40F585", redemptions: "#f5715D" }
  const [dataRange, setDateRange] = useState("monthly")
  const [chartOptions, setChartOptions] = useState(pieChartOptions);
  const [chartData, setChartData] = useState(pieChartData);
  const [stats, setStats] = useState({
    earnings: 0,
    redemptions: 0,
    total_points: 0,
    earnings_percentage: 0,
    redemptions_percentage: 0
  });

  const getEarningRedemptionStats = async (dataRange) => {
    try {
      const response = await DashboardService.getEarningRedemptionStats(dataRange);
      if (response.status == 1) {
        const { earnings, redemptions, total_points, earnings_percentage, redemptions_percentage
        } = response.data;
        setStats(response.data)
        setChartOptions((prev) => ({
          ...prev,
          labels: ["Earn", "Redeem"],
          colors: [colors.earnings, colors.redemptions],
          fill: { colors: [colors.earnings, colors.redemptions] },
        }))
        setChartData([Number(earnings_percentage.toFixed(2)), Number(redemptions_percentage.toFixed(2))])
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to load dashboard stats",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    }
  };

  useEffect(() => {
    getEarningRedemptionStats(dataRange)
  }, [dataRange])
  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Earning Stats
          </h4>
        </div>


        <div className="mb-6 flex items-center justify-center">
          <div className="relative">
            <FaRegCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600" />
            <select
              value={dataRange}
              onChange={(e) => setDateRange(e.target.value)}
              className="pl-8 linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80"
            >
              <option value="weekly">
                This Week
              </option>
              <option value="monthly" className="text-sm font-medium text-gray-600">
                This Month
              </option>
              <option value="yearly">
                This Year
              </option>
            </select>
          </div>
        </div>
      </div>

      <div className="mb-auto flex h-[220px] w-full items-center justify-center">
        <PieChart
          options={chartOptions}
          series={chartData}
        />
      </div>
      <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-brand-500" />
            <p className="ml-1 text-sm font-normal text-gray-600">Earnings</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
            {stats.earnings_percentage.toFixed(2)}%<br />
            <span className="text-green-600">{formatNumber(stats.earnings, 1)}</span>
          </p>
        </div>

        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-red-500" />
            <p className="ml-1 text-sm font-normal text-gray-600">Redemptions</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {stats.redemptions_percentage.toFixed(2)}%<br />
            <span className="text-red-600">{formatNumber(stats.redemptions, 1)}</span>
          </p>
        </div>
      </div>
    </Card>
  );
};

export default EarningsRedemptionsPieChart;

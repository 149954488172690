import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import setRequestAuthToken from "../../services/api-utils/setRequestAuthToken";
import AuthService from "../../services/auth/AuthService";
import * as ActionTypes from "../../actions/actionTypes";
import { getRolePermissions } from "actions/roles/roles.actions";

export const setToken = (token) => (dispatch) => {
  setRequestAuthToken(token);
  dispatch({
    type: ActionTypes.SET_TOKEN,
    payload: token,
  });
};

export const loadAuthToken = () => (dispatch) => {
  const token = localStorage.getItem("token") || null;
  dispatch(setToken(token));
};

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_AUTH_LOADING,
    payload: loading,
  });
};

export const setAuthUser = (user) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_AUTH_USER,
    payload: user,
  });
};

export const resetAuthState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_AUTH_STATE,
  });
};

export const loadUser = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await AuthService.validateUser();
    if (response.status == 1 && response.data) {
      dispatch(getRolePermissions());
      dispatch(setAuthUser(response.data));
      return;
    }
    dispatch(setAlert(response.message ?? "UnAuthorized", AlertTypes.ERROR));
    dispatch(userLogout());
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const userSignin = (credentials) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await AuthService.signin(credentials);
    if (response.status == 1 && response.data) {
      localStorage.setItem("token", response.data.token);
      dispatch(setToken(response.data.token));
      dispatch(
        setAlert(
          response.message || "Loged in Successfully",
          AlertTypes.SUCCESS
        )
      );
      // redirectTo('/dashboard')
      dispatch(loadUser());
      return;
    }
    dispatch(setAlert(response.message ?? "signin failed", AlertTypes.ERROR));
    // data.message.map((message) =>
    //   dispatch(setAlert(message, AlertTypes.ERROR))
    // );
  } catch (error) {
    dispatch(setAlert("something went wrong", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const requestForgetPassword =
  (payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await AuthService.requestForgetPassword(payload);
      if (response.status == 1) {
        return navigate(`/validate-otp?email=${payload.email}`);
      }
      dispatch(setAlert(response.message ?? "invalid email", AlertTypes.ERROR));
    } catch (error) {
      dispatch(setAlert("something went wrong", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const validateUserOTP = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await AuthService.validateUserOTP(payload);
    if (response.status == 1) {
      return navigate(
        `/reset-password?email=${payload.email}&otp=${payload.otp}`
      );
    }
    dispatch(setAlert(response.message ?? "invalid OTP", AlertTypes.ERROR));
  } catch (error) {
    dispatch(setAlert("something went wrong", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const resetPassword = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await AuthService.resetPassword(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "Password updated successfully",
          AlertTypes.SUCCESS
        )
      );
      return navigate(`/signin`);
    }
    dispatch(
      setAlert(response.message ?? "something went wrong", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("something went wrong", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const userLogout = (displayAlert = true) => (dispatch) => {
  localStorage.removeItem("token");
  dispatch(setToken(null));
  dispatch(setAuthUser(null));
  if (!displayAlert) return;
  dispatch(setAlert("Logged out", AlertTypes.ERROR));
};

import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { loadUser } from "actions/auth/auth.actions";
import Loader from "components/loaderSpinner/Loader";
import AdminLayout from "../layouts/AdminLayout";
import ForgotPassword from "./forgotPassword";
import Login from "./login";
import ResetPassword from "./resetPassword";
import ValidateOtp from "./validateOtp";
import { getSystemSettings } from "actions/settings/settings.action";

const LoadingPage = () => {
  const dispatch = useDispatch();
  const { token, user, loading } = useSelector((state) => state.auth);
  const { settings } = useSelector((state) => state.setting);

  useEffect(() => {
    if (!token) return;
    dispatch(loadUser());
  }, [token]);

  useEffect(() => {
    dispatch(getSystemSettings());
  }, [JSON.stringify(settings)]);

  // if (loading)
  //   return (
  //     <div className=" flex h-screen w-full items-center justify-center">
  //       <Loader />
  //     </div>
  //   );

  return (
    <div>
      {!user ? (
        <Router>
          <Routes>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/validate-otp" element={<ValidateOtp />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/signin" element={<Login />} />
            {loading ? (
              <Route
                path="/*"
                element={
                  <div className=" flex h-screen w-full items-center justify-center">
                    <Loader />
                  </div>
                }
              />
            ) : (
              <Route path="/*" element={<Login />} />
            )}
          </Routes>
        </Router>
      ) : loading ? (
        <div className=" flex h-screen w-full items-center justify-center">
          <Loader />
        </div>
      ) : (
        <Router>
          <Routes>
            <Route path="/signin" element={<Navigate to="/" replace />} />
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="/*" element={<AdminLayout />} />
          </Routes>
        </Router>
      )}
    </div>
  );
};

export default LoadingPage;

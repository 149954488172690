import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import HeadlessSelect from "../components/headless/select.headless";
import { FORM_TYPE } from "constants/app.constants";

const initialFaqData = {
  name: "",
  code: "",
  type: "FIAT",
  point_rate: 0,
};

const CurencyForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
  handleDelete,
}) => {
  const navigate = useNavigate();
  // redux-state
  const { loading } = useSelector((state) => state.currencies);

  const [currencyData, setCurrencyData] = useState(initialFaqData);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;
    const { name, code, type, point_rate } = initialFormData;
    setCurrencyData(() => ({
      name,
      code,
      type: "FIAT",
      point_rate,
    }));
  }, [initialFormData]);

  const onChangeFormData = (name, value) => {
    setCurrencyData((prevData) => ({ ...prevData, [name]: value }));
  };

  // submit handler
  const handleSubmit = (redirect) => {
    if (!currencyData.name || !currencyData.code) return;

    let payload = { ...currencyData };
    if (payload.id) delete payload.id;

    handleFormSubmit(payload, redirect);
  };

  return (
    <div className="h-full w-full bg-white">
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/currencies")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected Currencies
              page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} Currency`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/currencies"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => handleSubmit(false),
                variant: "primary",
                },
                {
                  text: "Delete",
                  onClick: () => handleDelete(),
                  variant: "danger",
                },
              ]
            : []),
        ]}
      />

      <div className="flex flex-col gap-3 p-4">
        {/* name and code */}
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={currencyData.name}
              onChange={(e) => onChangeFormData("name", e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Code
            </label>
            <input
              type="text"
              name="code"
              value={currencyData.code}
              onChange={(e) => onChangeFormData("code", e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
        </div>
        {/* type and point_rate */}

        <div className="flex flex-auto flex-col items-start">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Point Rate
          </label>
          <input
            type="number"
            name="point_rate"
            value={currencyData.point_rate}
            onChange={(e) => onChangeFormData("point_rate", e.target.value)}
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
          />
        </div>

        {/* <div className="flex flex-auto flex-col items-start">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Type
          </label>
          <HeadlessSelect
            title={"Select Type"}
            options={["FIAT", "CRYPTO"]}
            selected={currencyData.type}
            onSelect={(value) => onChangeFormData("type", value)}
          />
        </div> */}

        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
          rounded-md px-6 py-2 font-semibold text-white focus:outline-none
          ${
            loading
              ? "bg-indigo-400 hover:bg-indigo-400"
              : "bg-indigo-500 hover:bg-indigo-600"
          }
          `}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CurencyForm;

import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../../actions/actionTypes";
import DashboardService from "../../services/dashboard/DashboardService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_DASHBOARD_LOADING,
    payload: loading,
  });
};

export const setDashboardStats = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_DASHBOARD_STATS,
    payload: data,
  });
};

export const resetDashboardState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_DASHBOARD_STATE,
  });
};

export const getDashboardStats = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await DashboardService.getDashboardStats();
    if (response.status == 1) {
      dispatch(setDashboardStats(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load dashboard stats",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

import { setAlert } from "actions/alert/alert.actions";
import { FORM_TYPE } from "constants/app.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertTypes } from "reducers/alert/alert.reducer";
import ConfirmationModal from "views/admin/components/ConfirmationModal";
import CardHeader from "views/admin/components/cardHeader";
import AreaRegion from "./areaRegion";

const initialAreaData = {
  name: "",
  country_id: "",
  state_id: "",
  city_id: "",
  latitude: "",
  longitude: ""
};

const AreaForm = ({ formType, initialFormData, handleFormSubmit,handleDelete }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.region);
  const [areaData, setAreaData] = useState(initialAreaData);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    if (!initialFormData && formType == FORM_TYPE.UPDATE) return;
    setAreaData((prevData) => ({
      ...prevData,
      ...initialFormData,
      country_id: initialFormData?.country_id?.id || "",
      state_id: initialFormData?.state_id?.id || "",
      city_id: initialFormData?.city_id?.id || "",
    }));
  }, [initialFormData]);

  const handleOnChange = ({ name, value }) => {
    setAreaData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (redirect) => {
    const errors = [];
    if (!areaData.name) errors.push("name is required");
    if (isNaN(areaData.latitude) || isNaN(areaData.longitude))
      errors.push("latitude and longitude should be numbers");

    if (errors.length)
      return errors.forEach((error) =>
        dispatch(setAlert(error, AlertTypes.ERROR))
      );

    let payload = { ...areaData };

    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialAreaData[key] == undefined) {
        delete payload[key];
      }
    });

    handleFormSubmit(payload, redirect);
  };

  return (
    <div className="h-full w-full bg-white">
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/areas")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to areas
              page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} Area`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/areas"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => handleSubmit(false),
                variant: "primary",
                },
                {
                  text: "Delete",
                  onClick: () => handleDelete(),
                  variant: "danger",
                },
              ]
            : []),
        ]}
      />
      <div className="flex flex-col gap-3 p-4">
        <div className="w-full">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Name
          </label>
          <input
            onChange={(e) => handleOnChange(e.target)}
            type="text"
            name="name"
            value={areaData.name}
            className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
          />
        </div>

        {/* Country, State and City */}
        <AreaRegion
          areaData={areaData}
          setAreaData={setAreaData}
          handleOnChange={handleOnChange}
        />
        {/* Address Latitude Longitude */}
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Latitude
            </label>
            <input
              onChange={(e) => handleOnChange(e.target)}
              type="text"
              name="latitude"
              value={areaData.latitude}
              className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
            />
          </div>
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Longitude
            </label>
            <input
              onChange={(e) => handleOnChange(e.target)}
              type="text"
              name="longitude"
              value={areaData.longitude}
              className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
            />
          </div>
        </div>
        {/* {error ? <span className='text-red-600 p-2'>{error}</span> : null} */}
        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
            rounded-md px-6 py-2 font-semibold text-white focus:outline-none
            ${
              loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
            }
            `}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AreaForm;
import { setAlert } from "actions/alert/alert.actions";
import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertTypes } from "../../../reducers/alert/alert.reducer";
import ConfirmationModal from "../components/ConfirmationModal";
import { getRoles } from "actions/roles/roles.actions";
import MultiSelect from "../components/multiSelect";
import { FORM_TYPE } from "constants/app.constants";
import CardHeader from "../components/cardHeader";
import UpdatePassword from "../components/updatePassword";
import { validateCodePhoneNumber } from "utils/App.utils";
import { updateUserPassword } from "actions/users/users.actions";
import PhoneNumberInput from "../components/phoneNumberInput";

const initialUserData = {
  name: "",
  email: "",
  roles: [],
  password: "",
  confirmPassword: "",
  phone_number: {
    code: "",
    number: "",
  },
};

const UserForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
  handleDelete,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { loading } = useSelector((state) => state.auth);
  const { roles } = useSelector((state) => state.role);
  const [userData, setUserData] = useState(initialUserData);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showUpdatePasswordPopup, setShowUpdatePasswordPopup] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;

    setUserData((prevData) => ({
      ...prevData,
      ...initialFormData,
      password: "",
      confirmPassword: "",
    }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  const isValidCode = (code) => {
    return code === "" || /^\+\d{0,4}$/.test(code);
  };

  const isValidNumber = (number) => {
    return /^\d*$/.test(number);
  };

  const onChangeFormData = (name, value) => {
    if (name === "code" && isValidCode(value)) {
      setUserData((prevData) => ({
        ...prevData,
        phone_number: {
          ...prevData.phone_number,
          code: value,
        },
      }));
    } else if (name === "number" && isValidNumber(value)) {
      setUserData((prevData) => ({
        ...prevData,
        phone_number: {
          ...prevData.phone_number,
          number: value,
        },
      }));
    } else if (name !== "code" && name !== "number") {
      setUserData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = (redirect) => {
    const errors = [];

    const validPhoneNumber = validateCodePhoneNumber(
      userData.phone_number.code,
      userData.phone_number.number
    );
    // validate data
    Object.entries(userData).forEach(([key, value]) => {
      if (key == "roles" && !value.length)
        return errors.push(`atleast one role required`);
      if (
        value === "" &&
        !(
          formType === FORM_TYPE.UPDATE &&
          (key === "password" || key === "confirmPassword")
        )
      ) {
        errors.push(`${key} is required`);
      }
    });
    if (!validPhoneNumber) {
      errors.push(`Phone number is Incorrect`);
    }
    if (errors.length) {
      return errors.forEach((error) =>
        dispatch(setAlert(error, AlertTypes.ERROR))
      );
    }

    const payload = { ...userData };
    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialUserData[key] == undefined) {
        delete payload[key];
      }
    });
    if (payload.id) delete payload.id;

    handleFormSubmit(payload, redirect);
  };

  return (
    <div className="h-full w-full bg-white p-4">
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/users")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to Users
              page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} User`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/users"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },
          ...(formType === FORM_TYPE.UPDATE
            ? [
              {
                text: "Save & Continue Edit",
                onClick: () => handleSubmit(false),
                variant: "primary",
              },
              {
                text: "Update Password",
                onClick: () => setShowUpdatePasswordPopup(true),
                variant: "secondary",
              },
              {
                text: "Delete",
                onClick: () => handleDelete(),
                variant: "danger",
              },
            ]
            : []),
        ]}
      />
      {formType === FORM_TYPE.UPDATE && showUpdatePasswordPopup ? (
        <UpdatePassword
          handleSubmit={({ password, confirmPassword }) => dispatch(updateUserPassword(userData.id, { password, confirmPassword }))}
          handleCancel={() => setShowUpdatePasswordPopup(false)}
        />
      ) : null}
      <div className="flex flex-col gap-3">
        {/* name & email */}
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Name
            </label>
            <input
              type="text"
              name="name"
              placeholder="Type Name"
              value={userData.name}
              onChange={(e) => onChangeFormData("name", e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Email
            </label>
            <input
              type="email"
              name="email"
              placeholder="ex. something@gmail.com"
              value={userData.email}
              onChange={(e) => onChangeFormData("email", e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
        </div>
        <div className="flex flex-col justify-between gap-2 md:flex-row">

          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Roles
            </label>
            <MultiSelect
              options={roles.map((t) => ({ label: t.name, value: t.id }))}
              selectedValues={userData.roles}
              setSelectedValues={(values) => onChangeFormData("roles", values)}
            />
          </div>
          <div className="flex w-full flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Phone Number
            </label>
            <PhoneNumberInput
              phone_number={userData.phone_number}
              onPhoneNumberChange={(phone_number) => onChangeFormData("phone_number", phone_number)}
            />
          </div>
        </div>

        {/* <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              code
            </label>
            <input
              type="tel"
              name="code"
              placeholder="ex. +92"
              value={userData.phone_number.code}
              onChange={(e) => onChangeFormData("code", e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Number
            </label>
            <input
              type="tel"
              name="number"
              placeholder="ex. 1234567890"
              value={userData.phone_number.number}
              onChange={(e) => onChangeFormData("number", e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
        </div> */}

        {/* password & confirm password */}
        {formType === FORM_TYPE.CREATE ? (
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className=" relative flex flex-auto flex-col items-start">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Type password"
                value={userData.password}
                onChange={(e) => onChangeFormData("password", e.target.value)}
                className="relative mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
              />
              <button
                type="button"
                className="absolute right-3 top-12 -translate-y-1/2 transform"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? (
                  <FaEyeSlash className="h-4 w-4" />
                ) : (
                  <FaEye className="h-4 w-4" />
                )}
              </button>
            </div>

            <div className=" relative flex flex-auto flex-col items-start">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Confirm Password
              </label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirm password"
                value={userData.confirmPassword}
                onChange={(e) =>
                  onChangeFormData("confirmPassword", e.target.value)
                }
                className="relative mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
              />
              <button
                type="button"
                className="absolute right-3 top-12 -translate-y-1/2 transform"
                onClick={() => setShowConfirmPassword((prev) => !prev)}
              >
                {showConfirmPassword ? (
                  <FaEyeSlash className="h-4 w-4" />
                ) : (
                  <FaEye className="h-4 w-4" />
                )}
              </button>
            </div>
          </div>
        ) : null}

        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
                rounded-md px-6 py-2 font-semibold text-white focus:outline-none
                ${loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
              }
              `}
          >
            {loading ? "Saving..." : "Submit"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserForm;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import { FORM_TYPE } from "constants/app.constants";
import TabSelector from "../components/TabSelector";
import { getLanguages } from "actions/languages/languages.actions";
import { AlertTypes } from "reducers/alert/alert.reducer";
import { setAlert } from "actions/alert/alert.actions";

const initialFaqData = {
  title: "",
  description: "",
};

const FaqsForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
  handleDelete,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // redux-state
  const { loading, faqMultiLang } = useSelector((state) => state.faq);
  const { languages } = useSelector((state) => state.language);

  const [faqData, setFaqData] = useState(initialFaqData);
  const [multiLangData, setMultiLangData] = useState(initialFaqData);
  const [lang_id, setLang_id] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;
    const { status, created_by, ...restData } = initialFormData; // Destructure and exclude 'status'
    setFaqData((prevData) => ({
      ...prevData,
      ...restData,
    }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getLanguages());
  }, []);

  useEffect(() => {
    if (!lang_id) return;
    const activeLang = languages.find((lang) => lang.id == lang_id);
    const langData = faqMultiLang?.[activeLang?.code];
    setMultiLangData({ ...initialFaqData, ...langData });
  }, [lang_id]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (lang_id) {
      setMultiLangData((prevData) => ({ ...prevData, [name]: value }));
      return;
    }
    setFaqData((prevData) => ({ ...prevData, [name]: value }));
  };

  // submit handler
  const handleSubmit = (redirect) => {
    if (!faqData.title || !faqData.description) {
      dispatch(setAlert("All form fields are required", AlertTypes.ERROR))
      return;
    }

    let payload = { ...faqData };
    if (payload.id) delete payload.id;

    if (lang_id) payload = { ...multiLangData, lang_id };
    handleFormSubmit(payload, redirect);
  };

  return (
    <div className="h-full w-full bg-white">
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/faqs")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected FAQs page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} FAQ`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/faqs"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => handleSubmit(true),
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
              {
                text: "Save & Continue Edit",
                onClick: () => handleSubmit(false),
                variant: "primary",
              },
              {
                text: "Delete",
                onClick: () => handleDelete(),
                variant: "danger",
              },
            ]
            : []),
        ]}
      />

      <div className="flex flex-col gap-3 p-4">
        {/* Tabs */}
        {formType == FORM_TYPE.UPDATE ? (
          <div className="">
            <TabSelector
              selectors={[
                { name: "Standard", value: null },
                ...languages.map((lang) => ({
                  icon: lang.flag_image,
                  name: lang.name,
                  value: lang.id,
                })),
              ]}
              activeSelector={lang_id}
              setActiveSelector={(value) => setLang_id(value)}
            />
          </div>
        ) : null}
        <div className={`flex flex-col justify-between gap-2`}>
          <div className="flex flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Title
            </label>
            <input
              type="text"
              name="title"
              value={lang_id ? multiLangData.title : faqData.title}
              onChange={handleOnChange}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
          <div className="flex flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Description
            </label>
            <textarea
              name="description"
              value={lang_id ? multiLangData.description : faqData.description}
              onChange={handleOnChange}
              className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
              rows="2"
            />
          </div>
        </div>

        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit(true)}
            className={`
          rounded-md px-6 py-2 font-semibold text-white focus:outline-none
          ${loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
              }
          `}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FaqsForm;

import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ConfirmationModal from "../components/ConfirmationModal";
import FormFileUploader from "../components/fileUploader";
import dayjs from "dayjs";
import HeadlessSelect from "../components/headless/select.headless";
import convertToFormData from "utils/ConvertToFormData";
import CardHeader from "../components/cardHeader";
import { getLanguages } from "actions/languages/languages.actions";
import TabSelector from "../components/TabSelector";
import { FORM_TYPE } from "constants/app.constants";
import HeadlessSelectObject from "../components/headless/selectObject.headless";
import { getMerchants } from "actions/merchants/merchants.actions";
import validateSliderForm from "utils/sliders/ValidateSliderForm";
import { setAlert } from "actions/alert/alert.actions";
import { AlertTypes } from "reducers/alert/alert.reducer";
import { SLIDER_TYPE } from "constants/slider.constants";
import { SLIDER_STATUS } from "constants/slider.constants";
import { IMAGE_SIZES } from "constants/app.constants";
import { submitFormHandler } from "utils/App.utils";

const initilMultiLangData = {
  title: "",
  image: "",
};

const initialSliderData = {
  title: "",
  status: SLIDER_STATUS.ACTIVE,
  image: "",
  startDate: "",
  endDate: "",
  type: SLIDER_TYPE.COMMON,
  entity_type: "",
  entity_id: "",
  action_url: "",
};

const SliderForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
  handleDelete,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // redux-state
  const { loading, sliderMultiLang } = useSelector((state) => state.slider);
  const { languages } = useSelector((state) => state.language);
  const { merchants } = useSelector((state) => state.merchant);
  const shouldRedirect = useRef(true);

  // local-state
  const formRef = useRef(null);
  const [sliderData, setSliderData] = useState(initialSliderData);
  const [multiLangData, setMultiLangData] = useState(initilMultiLangData);
  const [lang_id, setLang_id] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;
    const { created_by, ...remainingData } = initialFormData;
    setSliderData((prevData) => ({
      ...prevData,
      ...remainingData,
      startDate: initialFormData?.startDate ? dayjs(initialFormData.startDate).format("YYYY-MM-DD") : "",
      endDate: initialFormData?.endDate ? dayjs(initialFormData.endDate).format("YYYY-MM-DD") : "",
    }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getLanguages(1,1000));
    dispatch(getMerchants(1, 1000));
  }, []);

  useEffect(() => {
    if (!lang_id) return;
    const activeLang = languages.find((lang) => lang.id == lang_id);
    const langData = sliderMultiLang?.[activeLang?.code];
    setMultiLangData({ ...initilMultiLangData, ...langData });
  }, [lang_id]);

  // submit-handler
  const handleSubmit = (e) => {
    e?.preventDefault(e);

    let payload = {};
    if (formType == FORM_TYPE.UPDATE && lang_id) {
      payload = { ...multiLangData, lang_id };
    } else {
      payload = { ...sliderData };
      if (payload.id) delete payload.id;
      const { isValid, errors } = validateSliderForm(formType, sliderData);

      if (!isValid) {
        errors.forEach((error) => dispatch(setAlert(error, AlertTypes.ERROR)));
        return;
      }
    }
    const fromData = convertToFormData(payload);
    handleFormSubmit(fromData, shouldRedirect.current);
    shouldRedirect.current = true;
  };

  const onChangeFormData = ({ name, value }) => {
    if (lang_id) {
      setMultiLangData((prevState) => ({ ...prevState, [name]: value }));
      return;
    }
    setSliderData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="h-full w-full bg-white">
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/sliders")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to Sliders
              page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} Slider`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/sliders"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => {
              submitFormHandler(formRef.current);
            },
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
              {
                text: "Save & Continue Edit",
                onClick: () => {
                  shouldRedirect.current = false;
                  submitFormHandler(formRef.current);
                },
                variant: "primary",
              },
              {
                text: "Delete",
                onClick: () => handleDelete(),
                variant: "danger",
              },
            ]
            : []),
        ]}
      />
      <div className="flex flex-col gap-3 p-4">
        {/* Language Tabs */}
        {formType == FORM_TYPE.UPDATE ? (
          <div className="">
            <TabSelector
              selectors={[
                { name: "Standard", value: null },
                ...languages.map((lang) => ({
                  icon: lang.flag_image,
                  name: lang.name,
                  value: lang.id,
                })),
              ]}
              activeSelector={lang_id}
              setActiveSelector={(value) => setLang_id(value)}
            />
          </div>
        ) : null}

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="flex flex-col gap-4 p-4"
        >
          <div className={`flex flex-col justify-between gap-2`}>
            {/* image */}
            <div className="flex h-full w-full flex-col items-start gap-1">
              <label
                for="image"
                className="mb-1 block text-base font-medium text-gray-800"
              >
                Image
              </label>
              <FormFileUploader
                size={IMAGE_SIZES.SLIDER}
                image={lang_id ? multiLangData.image : sliderData.image}
                handleUpload={(acceptedFiles) =>
                  onChangeFormData({ name: "image", value: acceptedFiles[0] })
                }
              />
            </div>

            {/* title */}
            <div className="flex flex-col justify-between gap-2 md:flex-row">
              <div className="w-full">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Title
                </label>
                <input
                  required
                  type="text"
                  name="title"
                  value={lang_id ? multiLangData.title : sliderData.title}
                  onChange={(e) => onChangeFormData(e.target)}
                  className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
                />
              </div>
            </div>
          </div>

          {!(formType == FORM_TYPE.UPDATE && lang_id) ? (
            <Fragment>
              <div className="flex flex-col justify-between gap-2 md:flex-row">
                {/* status */}
                <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Status
                  </label>
                  <HeadlessSelect
                    title={"select Status"}
                    options={Object.values(SLIDER_STATUS)}
                    selected={sliderData.status}
                    onSelect={(value) =>
                      onChangeFormData({ name: "status", value })
                    }
                  />
                </div>
              </div>

              {/* dates */}
              <div className="flex flex-col justify-between gap-2 md:flex-row">
                {" "}
                {/* from-date */}
                <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    From Date
                  </label>
                  <input
                    // required
                    type="date"
                    name="startDate"
                    value={sliderData.startDate}
                    onChange={(e) => onChangeFormData(e.target)}
                    className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
                  />
                </div>
                {/* to date */}
                <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    To Date
                  </label>
                  <input
                    // required
                    type="date"
                    name="endDate"
                    value={sliderData.endDate}
                    onChange={(e) => onChangeFormData(e.target)}
                    className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
                  />
                </div>
              </div>

              <div className="flex flex-col justify-between gap-2 md:flex-row">
                {/* Slider Type */}
                <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Slider Type
                  </label>
                  <HeadlessSelect
                    title={"select slider type"}
                    options={Object.values(SLIDER_TYPE)}
                    selected={sliderData.type}
                    onSelect={(value) =>
                      onChangeFormData({ name: "type", value })
                    }
                  />
                </div>
              </div>

              {sliderData.type == SLIDER_TYPE.INTERNAL ? (
                <div className="flex flex-col justify-between gap-2 md:flex-row">
                  {/* action_url */}
                  <div className="w-full">
                    <label className="mb-1 block text-base font-medium text-gray-800">
                      Entity Type
                    </label>
                    <HeadlessSelect
                      title={"select entity"}
                      options={["Merchant"]}
                      selected={sliderData.entity_type}
                      onSelect={(value) =>
                        onChangeFormData({ name: "entity_type", value })
                      }
                    />
                  </div>
                  <div className="w-full">
                    <label className="mb-1 block text-base font-medium text-gray-800">
                      Entity
                    </label>
                    <HeadlessSelectObject
                      title={
                        !sliderData.entity_type || !merchants.length
                          ? "NO entity found"
                          : "select entity"
                      }
                      disable={!sliderData.entity_type || !merchants.length}
                      options={merchants.map((merchant) => ({
                        name: merchant.name,
                        value: merchant.id,
                      }))}
                      selected={sliderData.entity_id}
                      onSelect={(value) =>
                        onChangeFormData({ name: "entity_id", value })
                      }
                    />
                  </div>
                </div>
              ) : sliderData.type == SLIDER_TYPE.EXTERNAL ? (
                <div className="flex flex-col justify-between gap-2 md:flex-row">
                  {/* action_url */}
                  <div className="w-full">
                    <label className="mb-1 block text-base font-medium text-gray-800">
                      Action URI
                    </label>
                    <input
                      required
                      type="url"
                      name="action_url"
                      value={sliderData.action_url}
                      onChange={(e) => onChangeFormData(e.target)}
                      className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
                    />
                  </div>
                </div>
              ) : null}
            </Fragment>
          ) : null}
          {/* buttons */}
          <div className="flex justify-between">
            <button
              disabled={loading}
              type="submit"
              // onClick={() => handleSubmit()}
              className={`
          rounded-md px-6 py-2 font-semibold text-white focus:outline-none
          ${loading
                  ? "bg-indigo-400 hover:bg-indigo-400"
                  : "bg-indigo-500 hover:bg-indigo-600"
                }
          `}
            >
              {loading ? "Saving..." : "Save"}
            </button>
            <button
              type="button"
              onClick={() => setShowCancelModal(true)}
              className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SliderForm;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import convertToFormData from "utils/ConvertToFormData";
import { FORM_TYPE } from "constants/app.constants";
import CardHeader from "../components/cardHeader";
import { validateCodePhoneNumber } from "utils/App.utils";
import { isValidNumber } from "utils/App.utils";
import { isValidCode } from "utils/App.utils";
import { setAlert } from "actions/alert/alert.actions";
import { AlertTypes } from "reducers/alert/alert.reducer";
import UpdatePassword from "../components/updatePassword";
import { updateBrandPassword } from "actions/Brands/brands.action";
import PhoneNumberInput from "../components/phoneNumberInput";
import TabSelector from "../components/TabSelector";
import { getLanguages } from "actions/languages/languages.actions";

const initialFacilityData = {
  name: "",
};

const FacilityForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
  handleDelete,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { languages } = useSelector((state) => state.language);
  const { loading, facilityMultiLang } = useSelector((state) => state.facility);
  const [facilityData, setFacilityData] = useState(initialFacilityData);

  const [multiLangData, setMultiLangData] = useState(initialFacilityData);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const [lang_id, setLang_id] = useState(null);

  useEffect(() => {
    if (!initialFormData && formType == FORM_TYPE.UPDATE) return;
    setFacilityData((prevData) => ({ ...prevData, ...initialFormData }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getLanguages(1,1000));
  }, []);

  useEffect(() => {
    if (!lang_id) return;
    const activeLang = languages.find((lang) => lang.id == lang_id);
    const langData = facilityMultiLang?.[activeLang?.code];
    setMultiLangData({ ...initialFacilityData, ...langData });
  }, [lang_id]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (lang_id) {
      setMultiLangData({ ...multiLangData, [name]: value });
      return;
    }
    setFacilityData({ ...facilityData, [name]: value });
  };

  const handleSubmit = (redirect) => {
    let payload = { ...facilityData };
    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialFacilityData[key] == undefined) {
        delete payload[key];
      }
    });
    if (lang_id) payload = { ...multiLangData, lang_id };
    handleFormSubmit(payload, redirect);
  };

  return (
    <div className="h-full w-full bg-white">
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/facility")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to
              Facilities page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} Facility`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/facility"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => handleSubmit(false),
                variant: "primary",
                },
                {
                  text: "Delete",
                  onClick: () => handleDelete(),
                  variant: "danger",
                },
              ]
            : []),
        ]}
      />

      <div className="flex flex-col gap-3 p-4">
        {formType == FORM_TYPE.UPDATE ? (
          <div className="">
            <TabSelector
              selectors={[
                { name: "Standard", value: null },
                ...languages.map((lang) => ({
                  icon: lang.flag_image,
                  name: lang.name,
                  value: lang.id,
                })),
              ]}
              activeSelector={lang_id}
              setActiveSelector={(value) => setLang_id(value)}
            />
          </div>
        ) : null}
        {/* name */}
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Name
            </label>
            <input
              type="text"
              name="name"
              placeholder="Facility Name"
              value={lang_id ? multiLangData.name : facilityData.name}
              onChange={handleOnChange}
              className="block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
        </div>

        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
            rounded-md px-6 py-2 font-semibold text-white focus:outline-none
            ${
              loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
            }
            `}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FacilityForm;

import { setAlert } from "actions/alert/alert.actions";
import { getMerchants } from "actions/merchants/merchants.actions";
import { CART_RULE_ACTION_TYPE, FORM_TYPE } from "constants/app.constants";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertTypes } from "../../../reducers/alert/alert.reducer";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import FormSectionHeader from "../components/formSectionHeader";
import MultiSelect from "../components/multiSelect";
import ToggleSwitch from "../components/toggleSwitch";
import HeadlessSelect from "../components/headless/select.headless";
import { CART_RULE_TYPE } from "constants/app.constants";

const initialCartRuleData = {
  name: "",
  rule_type: CART_RULE_TYPE.MERCHANT,
  is_published: true,
  conditions: {
    order_count: {
      isEnable: true,
      min_value: 0,
      max_value: 0,
    },
    order_amount: {
      isEnable: true,
      min_value: 1,
      max_value: 1,
    },
    merchants: ["All"],
    start_date: "",
    end_date: "",
  },
  action: {
    type: CART_RULE_ACTION_TYPE.PERCENTAGE,
    value: 0,
    capping: 0,
  },
};

const CartRuleForm = ({ formType, initialFormData, handleFormSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.rule);
  const { merchants } = useSelector((state) => state.merchant);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cartRuleData, setCartRuleData] = useState(initialCartRuleData);

  useEffect(() => {
    if (!initialFormData) return;
    setCartRuleData((prevData) => ({
      ...prevData,
      ...initialFormData,
      conditions: {
        ...initialFormData.conditions,
        merchants: initialFormData.conditions.merchants.length ? initialFormData.conditions.merchants : ["All"],
        start_date: dayjs(initialFormData.conditions.start_date).format("YYYY-MM-DD"),
        end_date: dayjs(initialFormData.conditions.end_date).format("YYYY-MM-DD"),
      },
    }));
  }, [initialFormData]);


  useEffect(() => {
    dispatch(getMerchants(1, 1000));
  }, []);

  const handleChangeCartRuleData = (key, value) => {
    setCartRuleData((prev) => ({
      ...prev,
      [key]: value,
    }))
    if (key === "rule_type" && value === CART_RULE_TYPE.NETWORK) {
      handleChangeCondition("merchants", ["All"])
    }
  };

  const handleChangeCondition = (key, value) => {
    const conditions = { ...cartRuleData.conditions }
    conditions[key] = value
    handleChangeCartRuleData("conditions", conditions)
  };

  const handleChangeConditionDetails = (conditionName, key, value) => {
    const condition = { ...cartRuleData.conditions[conditionName] }
    condition[key] = value
    handleChangeCondition(conditionName, condition)
  };

  const handleChangeDiscountAction = (key, value) => {
    const action = { ...cartRuleData.action }
    action[key] = value
    handleChangeCartRuleData("action", action)
  };

  const handleSubmit = () => {
    let payload = {};

    payload = { ...cartRuleData };
    const errors = [];

    let { name, rule_type, is_published, conditions, action } = payload
    payload.conditions = { ...conditions, merchants: conditions.merchants.filter(merchant => merchant !== "All") }


    if (!name) errors.push("Name is required");
    if (!rule_type) errors.push("Rule type is required");

    if (errors.length) {
      return errors.forEach((error) =>
        dispatch(setAlert(error, AlertTypes.ERROR))
      );
    }

    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialCartRuleData[key] === undefined) {
        delete payload[key];
      }
    });

    handleFormSubmit(payload);
  };

  return (
    <div className="h-full w-full bg-white ">
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/cartRules")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to Cart
              Rules page.
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} Earning Rule`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/cartRules"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
              {
                text: "Save & Continue Edit",
                onClick: () => handleSubmit(false),
                variant: "primary",
              },
            ]
            : []),
        ]}
      />
      <div className="flex flex-col gap-4 p-4">
        {/* Basic Information */}
        <Fragment>
          {/* <FormSectionHeader text="Basic Information" /> */}
          {/* Name  */}
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="w-full">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Name
              </label>
              <input
                required
                type="text"
                name="name"
                value={cartRuleData.name}
                onChange={(e) => handleChangeCartRuleData("name", e.target.value)}
                className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
              />
            </div>
            {/* rule type */}
            <div className="w-full">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Rule Type
              </label>
              <HeadlessSelect
                title={"select rule type"}
                options={Object.values(CART_RULE_TYPE)}
                selected={cartRuleData.rule_type}
                onSelect={(value) => handleChangeCartRuleData("rule_type", value)}
              />
            </div>
          </div>

          <div className="flex flex-col justify-between gap-2 md:flex-row">
            {/* rule status */}
            <div className="w-full">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Status
              </label>
              <HeadlessSelect
                title={"select rule status"}
                options={["Publish", "Unpublish"]}
                selected={cartRuleData.is_published ? "Publish" : "Unpublish"}
                onSelect={(value) => handleChangeCartRuleData("is_published", value === "Publish")}
              />
            </div>
          </div>
        </Fragment>

        {/* Action */}
        <Fragment>
          <FormSectionHeader text="Discount Action" />
            <div className="flex flex-col justify-between gap-2 md:flex-row">
              {/* type */}
              <div className="w-full">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Type
                </label>
                <HeadlessSelect
                  title={"select type"}
                  options={Object.values(CART_RULE_ACTION_TYPE)}
                  selected={cartRuleData.action.type}
                  onSelect={(value) => handleChangeDiscountAction("type", value)}
                />
              </div>

              {/* value */}
              <div className="w-full">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Value
                </label>
                <input
                  type="text"
                  name="value"
                  value={cartRuleData.action.value}
                  onChange={(e) => handleChangeDiscountAction("value", isNaN(e.target.value) ? cartRuleData.action.value : Number(e.target.value))}
                  className="w-full rounded-md border border-gray-300 px-2 py-2 text-sm focus:border-indigo-500 focus:outline-none"
                />
              </div>
              {/* capping */}
              {cartRuleData.action.type === CART_RULE_ACTION_TYPE.PERCENTAGE ? (
                <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Maximum discount
                  </label>
                  <input
                    type="text"
                    name="capping"
                    value={cartRuleData.action.capping}
                    onChange={(e) => handleChangeDiscountAction("capping", isNaN(e.target.value) ? cartRuleData.action.capping : Number(e.target.value))}
                    className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
                  />
                </div>
              ) : null}
          </div>
        </Fragment>

        {/* conditions */}
        <Fragment>
          <FormSectionHeader text="Conditions" />
          {/* order count */}
            <div className="flex w-full flex-col justify-between gap-2 sm:flex-row ">
              <div className="flex w-full max-w-60 items-center justify-start gap-6">
                <ToggleSwitch
                  isEnabled={cartRuleData.conditions.order_count.isEnable}
                  handleChange={() => {
                    handleChangeConditionDetails("order_count", "isEnable", !cartRuleData.conditions.order_count.isEnable)
                  }}
                />
                <p className="mb-1 block text-base font-medium text-gray-800">
                  Order Count
                </p>
              </div>

              <div className="flex w-full items-center justify-start gap-5">
                <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Min
                  </label>
                  <input
                    disabled={!cartRuleData.conditions.order_count.isEnable}
                    type="number"
                    name="min_value"
                    value={cartRuleData.conditions.order_count.min_value}
                    onChange={(e) => handleChangeConditionDetails("order_count", "min_value", isNaN(e.target.value) ? cartRuleData.conditions.order_count.min_value : Number(e.target.value))}
                    className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
                  />
                </div>
                <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Max
                  </label>
                  <input
                    disabled={!cartRuleData.conditions.order_count.isEnable}
                    type="number"
                    name="max_value"
                    value={cartRuleData.conditions.order_count.max_value}
                    onChange={(e) => handleChangeConditionDetails("order_count", "max_value", isNaN(e.target.value) ? cartRuleData.conditions.order_count.max_value : Number(e.target.value))}
                    className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
                  />
                </div>
              </div>
            </div>

          {/* order amount */}
            <div className="flex w-full flex-col justify-between gap-2 sm:flex-row ">
              <div className="flex w-full max-w-60 items-center justify-start gap-6">
                <ToggleSwitch
                  isEnabled={cartRuleData.conditions.order_amount.isEnable}
                  handleChange={() => {
                    handleChangeConditionDetails("order_amount", "isEnable", !cartRuleData.conditions.order_amount.isEnable)
                  }}
                />
                <p className="mb-1 block text-base font-medium text-gray-800">
                  Order Amount
                </p>
              </div>

              <div className="flex w-full items-center justify-start gap-5">
                <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Min
                  </label>
                  <input
                    disabled={!cartRuleData.conditions.order_amount.isEnable}
                    type="number"
                    name="min_value"
                    value={cartRuleData.conditions.order_amount.min_value}
                    onChange={(e) => handleChangeConditionDetails("order_amount", "min_value", isNaN(e.target.value) ? cartRuleData.conditions.order_amount.min_value : Number(e.target.value))}
                    className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
                  />
                </div>
                <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Max
                  </label>
                  <input
                    disabled={!cartRuleData.conditions.order_amount.isEnable}
                    type="number"
                    name="max_value"
                    value={cartRuleData.conditions.order_amount.max_value}
                    onChange={(e) => handleChangeConditionDetails("order_amount", "max_value", isNaN(e.target.value) ? cartRuleData.conditions.order_amount.max_value : Number(e.target.value))}
                    className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
                  />
                </div>
              </div>
          </div>

          {/* merchants */}
          {cartRuleData.rule_type === CART_RULE_TYPE.MERCHANT ?
            <div className="flex w-full flex-col justify-between gap-2 sm:flex-row ">

              <div className="w-full gap-2">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Select Merchants
                </label>
                <MultiSelect
                  // disabled
                  options={[
                    { label: 'All', value: 'All' },
                    ...merchants.map((t) => ({
                      label: t.name,
                      value: t.id,
                    }))
                  ]}
                  selectedValues={cartRuleData.conditions.merchants}
                  setSelectedValues={(values) => {
                    // move the logic in a function i.e handleChangeMerchants
                    let temp_values = values
                    const temp_merchants = cartRuleData.conditions.merchants
                    if (!temp_values.length || (!temp_merchants.includes("All") && values.includes("All"))) temp_values = ["All"]
                    else temp_values = temp_values.filter(value => value !== "All")

                    handleChangeCondition("merchants", temp_values)
                  }}
                />
              </div>
            </div>
            : null
          }

          {/* start & end date */}
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="w-full">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Start Date
              </label>
              <input
                type="date"
                name="start_date"
                value={cartRuleData.conditions.start_date}
                onChange={(e) => handleChangeCondition("start_date", e.target.value)}
                className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
              />
            </div>
            <div className="w-full">
              <label className="mb-1 block text-base font-medium text-gray-800">
                End Date
              </label>
              <input
                required
                type="date"
                name="end_date"
                value={cartRuleData.conditions.end_date}
                onChange={(e) => handleChangeCondition("end_date", e.target.value)}
                className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
              />
            </div>
          </div>
        </Fragment>

        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
                rounded-md px-6 py-2 font-semibold text-white focus:outline-none
                ${loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
              }
              `}
          >
            {loading ? "Saving..." : "Submit"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartRuleForm;

import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getDashboardStats = () => {
  return RestClient.Get(API_URL.GET_DASHBOARD_STATS);
};

const getEarningRedemptionChartData = (dateRange) => {
  const range = dateRange ? `dateRange=${dateRange}` : "";
  return RestClient.Get(
    `${API_URL.GET_EARNING_REDEMPTION_CHART_DATE}?${range}`
  );
};

const getTopMerchants = () => {
  return RestClient.Get(API_URL.GET_TOP_MERCHANTS);
};

const getTopCustomers = () => {
  return RestClient.Get(API_URL.GET_TOP_CUSTOMERS);
};

const getEarningRedemptionStats = (dateRange) => {
  const range = dateRange ? `dateRange=${dateRange}` : "";
  return RestClient.Get(`${API_URL.GET_EARNING_REDEMPTION_STATS}?${range}`);
};

const getLanguagesCustomersStats = () => {
  return RestClient.Get(API_URL.GET_LANGUAGES_CUSTOMERS_STATS);
};

const exportedObject = {
  getDashboardStats,
  getEarningRedemptionChartData,
  getTopMerchants,
  getTopCustomers,
  getEarningRedemptionStats,
  getLanguagesCustomersStats,
};

export default exportedObject;

import { createMerchantType } from "actions/merchants/merchants.actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MerchantTypeForm from "./merchantTypeForm";
import { FORM_TYPE } from "constants/app.constants";

const CreateMerchantType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="h-full w-full">
      <MerchantTypeForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(payload) =>
          dispatch(createMerchantType(payload, navigate))
        }
      />
    </div>
  );
};

export default CreateMerchantType;

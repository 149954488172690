export const messages = [
  {
    id: 1,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Hi, I need help with my ticket.",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message: "Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message: "Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message:
      "Sure, I can help with that. Please provide more details.Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message: "Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message: "Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message: "Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message: "Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  {
    id: 1,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Hi, I need help with my ticket.",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message: "Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message: "Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message:
      "Sure, I can help with that. Please provide more details.Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message: "Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message: "Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message: "Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  {
    id: 2,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=20C997",
    message: "Sure, I can help with that. Please provide more details.",
    fromAdmin: true,
  },
  {
    id: 3,
    userImage:
      "https://img.icons8.com/?size=100&id=14736&format=png&color=000000",
    message: "Here are the details...",
    fromAdmin: false,
  },
  // Add more dummy messages as needed
];

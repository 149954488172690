import { getDashboardStats } from "actions/dashboard/dashboard.action";
import MiniCalendar from "components/calendar/MiniCalendar";
import Widget from "components/widget/Widget";
import { useEffect } from "react";
import { AiFillShop } from "react-icons/ai";
import { FaArrowDown, FaArrowUp, FaDollarSign, FaImages } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { TbArrowsUpDown, TbCurrencySolana } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "utils/App.utils";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import EarningsRedemptionsLineChart from "./charts/earning-redemption-line";
import EarningsRedemptionsPieChart from "./charts/earning-redemption-pie";
import LanguagesCustomersPieChart from "./charts/languages-customers-pie";
import TopCustomersTable from "./charts/top-customers-table";
import TopMerchantsTable from "./charts/top-merchants-table";
import { columnsDataComplex } from "./variables/columnsData";
import tableDataComplex from "./variables/tableDataComplex.json";
import { MdCancel, MdCheckCircle } from "react-icons/md";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { stats } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getDashboardStats());
  }, []);

  return (
    <div>
      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-4">
        <Widget
          icon={<IoPerson className="h-7 w-7" />}
          title={"Total Customers"}
          subtitle={stats?.totalCustomers || 0}
          extra={
            <div className="flex w-full justify-between gap-5">
              <div className=" flex w-auto flex-col justify-center">
                <p className="font-dm text-xs font-medium text-gray-600">
                  Verified
                </p>
                <h4 className="flex items-center text-xs font-bold text-navy-700  dark:text-white">
                  <div className={`rounded-full `}>
                    <MdCheckCircle className="text-green-500" />
                  </div>
                  {stats?.verifiedCustomers}
                </h4>
              </div>
              <div className=" flex w-auto flex-col justify-center">
                <p className="font-dm text-xs font-medium text-gray-600">
                  Not Verified
                </p>
                <h4 className="flex items-center text-xs font-bold text-navy-700 dark:text-white">
                  <div className={`rounded-full `}>
                    <MdCancel className="text-red-500" />
                  </div>
                  {stats?.nonVerifiedCustomers}
                </h4>
              </div>
            </div>
          }
        />
        <Widget
          icon={<AiFillShop className="h-7 w-7" />}
          title={"Total Merchants"}
          subtitle={stats?.totalMerchants || 0}
        />
        <Widget
          icon={<FaImages className="h-7 w-7" />}
          title={"Total NFTs Mint"}
          subtitle={stats?.totalNftMints || 0}
        />
        <Widget
          icon={<TbCurrencySolana className="h-7 w-7" />}
          title={"Total Minting Fee"}
          subtitle={`${stats?.totalMintFees || 0} Solana`}
        />
        <Widget
          icon={<TbArrowsUpDown className="h-7 w-7" />}
          title={"Total Transactions"}
          subtitle={formatNumber(stats?.totalTransacrions || 0, 0)}
        />
        <Widget
          icon={<FaDollarSign className="h-7 w-7" />}
          title={"Total Customers Spending"}
          subtitle={"$" + formatNumber(stats?.totalCustomersSpending || 0, 3)}
        />
        <Widget
          icon={<FaArrowDown className="h-7 w-7" />}
          title={"Total Earnings"}
          subtitle={"$" + formatNumber(stats?.earnings?.total_points || 0, 2)}
          extra={
            <div className="flex w-full justify-between gap-5">
              <div className=" flex w-auto flex-col justify-center">
                <p className="font-dm text-xs font-medium text-gray-600">
                  Network earning
                </p>
                <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                  {"$" + formatNumber(stats?.earnings?.earning_network || 0, 2)}
                </h4>
              </div>
              <div className=" flex w-auto flex-col justify-center">
                <p className="font-dm text-xs font-medium text-gray-600">
                  Merchant earning
                </p>
                <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                  {"$" +
                    formatNumber(stats?.earnings?.earning_merchant || 0, 2)}
                </h4>
              </div>
            </div>
          }
        />
        <Widget
          icon={<FaArrowUp className="h-7 w-7" />}
          title={"Total Redemptions"}
          subtitle={
            "$" + formatNumber(stats?.redemptions?.total_points || 0, 2)
          }
          extra={
            <div className="flex w-full justify-between gap-5">
              <div className=" flex w-auto flex-col justify-center">
                <p className="font-dm text-xs font-medium text-gray-600">
                  Network redeem
                </p>
                <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                  {"$" +
                    formatNumber(
                      stats?.redemptions?.redemption_network || 0,
                      2
                    )}
                </h4>
              </div>
              <div className=" flex w-auto flex-col justify-center">
                <p className="font-dm text-xs font-medium text-gray-600">
                  Merchant redeem
                </p>
                <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                  {"$" +
                    formatNumber(
                      stats?.redemptions?.redemption_merchant || 0,
                      2
                    )}
                </h4>
              </div>
            </div>
          }
        />
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        {/* Total Spent Line */}
        <EarningsRedemptionsLineChart />

        {/* Pie Charts */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <EarningsRedemptionsPieChart />
          <LanguagesCustomersPieChart />
        </div>
      </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Top Merchant and Customers */}
        <div>
          <TopMerchantsTable />
        </div>
        <TopCustomersTable />

        {/* <WeeklyRevenue />

        Complex Table , Task & Calendar

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}

        {/* Daily Traffic */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;

import Card from "components/card";
import PieChart from "components/charts/PieChart";
import { Fragment, useState } from "react";
import { pieChartData, pieChartOptions } from "variables/charts";

import { useEffect } from "react";

import { setAlert } from "actions/alert/alert.actions";
import { useDispatch } from "react-redux";
import { AlertTypes } from "reducers/alert/alert.reducer";
import DashboardService from "services/dashboard/DashboardService";
import { formatNumber } from "utils/App.utils";
import { MdPerson } from "react-icons/md";

const LanguagesCustomersPieChart = () => {
  const dispatch = useDispatch();
  const [dataRange, setDateRange] = useState("monthly")
  const [chartOptions, setChartOptions] = useState(pieChartOptions);
  const [chartData, setChartData] = useState(pieChartData);
  const [stats, setStats] = useState({
    customers: {},
    percentage: {},
    icons: {},
  });

  const getLanguagesCustomersStats = async () => {
    try {
      const response = await DashboardService.getLanguagesCustomersStats();
      if (response.status == 1) {
        const { customers, percentage, icons } = response.data;
        setStats(response.data)
        setChartOptions((prev) => ({
          ...prev,
          labels: Object.keys(percentage),
          colors: undefined,
          fill: { colors: undefined },
        }))

        setChartData(Object.values(percentage).map(value => parseFloat(Number(value).toFixed(2))))
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to load dashboard stats",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    }
  };

  useEffect(() => {
    getLanguagesCustomersStats()
  }, [])

  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Language Customers
          </h4>
        </div>
      </div>
      <div className="mb-6 flex items-center justify-center"></div>

      <div className="mb-auto flex h-[220px] w-full items-center justify-center">
        <PieChart
          options={chartOptions}
          series={chartData}
        />
      </div>
      <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        {Object.entries(stats.percentage).map(([langauge, value], index) => (
          <Fragment>
            {index > 0 ? <div className="h-11 w-px bg-gray-300 dark:bg-white/10" /> : null}
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center">
                {/* <div className="h-2 w-2 rounded-full bg-brand-500" /> */}
                {stats.icons?.[langauge] ? <img src={stats.icons[langauge]} className="h-3 w-3 rounded-full" /> : null}
                <p className="ml-1 text-sm font-normal text-gray-600">{langauge}</p>
              </div>
              <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
                {value}%<br />
                <div className="flex gap-1 items-center text-gray-700">
                  <MdPerson title="customers" />
                  <span className="">{stats.customers?.[langauge] ? formatNumber(stats.customers[langauge], 0) : "_"}</span>
                </div>
              </p>
            </div>
          </Fragment>
        ))}
      </div>
    </Card>
  );
};

export default LanguagesCustomersPieChart;
